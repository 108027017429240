<script setup>
import { useMainStore } from "~/stores/main";

// -----------------------
// props & emits
// -----------------------
const props = defineProps({
  level: {
    type: Number,
    required: true,
  },
  ignoreChildren: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["leave-page"]);

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();
const { isIconFont, isIconImage, getAssetUrl } = useHelpers();
const { t } = useI18n();

// -----------------------
// reactive properties
// -----------------------
const extraClasses = ref("");

// -----------------------
// computed properties
// -----------------------
const hasIcons = computed(() => {
  return items.value.filter((i) => "icon" in i && i.icon !== null).length > 0;
});

const allProductsUrl = computed(() => {
  if (
    props.level === 2 &&
    mainStore.firstLevelSelected &&
    mainStore.firstLevelSelected.url
  ) {
    return mainStore.firstLevelSelected.url;
  } else if (
    props.level === 3 &&
    mainStore.secondLevelSelected &&
    mainStore.secondLevelSelected.url
  ) {
    return mainStore.secondLevelSelected.url;
  }

  return "#";
});

const showAllProductsLink = computed(() => {
  if (props.level === 1) {
    return false;
  } else if (props.level === 2 && mainStore.firstLevelSelected) {
    return !("hide_all_products_link" in mainStore.firstLevelSelected);
  } else if (props.level === 3 && mainStore.secondLevelSelected) {
    return !("hide_all_products_link" in mainStore.secondLevelSelected);
  }

  return true;
});

const computedClasses = computed(() => {
  const classes = ["level-" + props.level, extraClasses.value];

  if (items.value.length > 0) {
    classes.push("level-visible");
  }

  if (props.level === 3) {
    classes.push("custom-scrollbar overflow-y-auto max-h-[80vh]");
  }

  return classes.join(" ");
});

const items = computed(() => {
  if (props.level === 1) {
    return mainStore.firstLevelItems ?? [];
  } else if (props.level === 2) {
    return mainStore.secondLevelItems ?? [];
  } else if (props.level === 3) {
    return mainStore.thirdLevelItems ?? [];
  } else {
    return [];
  }
});

// -----------------------
// helper functions
// -----------------------
const toggle = (menuItem) => {
  if ("selected" in menuItem) {
    delete menuItem.selected;
    return;
  }

  mainStore.deselectMenu(items.value);
  menuItem.selected = true;
};

// -----------------------
// vue events
// -----------------------
watch(items, (newItems, oldItems) => {
  if (oldItems !== null && oldItems.length > 0) {
    if (newItems === null || newItems.length === 0) {
      extraClasses.value = "level-disappears";
    } else {
      extraClasses.value = "level-replaces";

      setTimeout(() => {
        extraClasses.value = "";
      }, 350);
    }
  } else {
    extraClasses.value = "";
  }
});

const handleNavigate = (menuItem) => {
  menuItem.focused = false;
  emit("leave-page", menuItem.url);
};
</script>

<template>
  <ul
    class="w-[328px] p-[10px] bg-white absolute level-container select-none"
    :class="computedClasses"
  >
    <template v-for="(menuItem, index) in items" :key="menuItem.name">
      <li
        class="leading-9 flex items-center font-medium text-sm"
        :class="{
          'text-brand-primary active': menuItem.focused || menuItem.selected,
          'font-semibold': menuItem.pressed,
          'mb-2': showAllProductsLink || index !== items.length - 1,
        }"
        @mouseover="menuItem.focused = true"
        @mouseleave="menuItem.focused = false"
        @mousedown="menuItem.pressed = true"
        @mouseup="menuItem.pressed = false"
      >
        <template v-if="hasIcons">
          <NuxtImg
            v-if="isIconImage(menuItem.icon)"
            :src="getAssetUrl(menuItem.icon)"
            class="ml-[22px] mr-[25px] flex-[0_0_25px] h-[25px]"
            :alt="menuItem.name"
            format="webp"
            loading="lazy"
            width="25"
          />
          <i
            v-else-if="isIconFont(menuItem.icon)"
            :class="menuItem.icon"
            class="text-2xl ml-[22px] mr-[25px] flex-[0_0_25px] h-[25px]"
          ></i>
          <div
            v-else
            class="inline-block ml-[22px] mr-[25px] flex-[0_0_25px] h-[25px]"
          ></div>
        </template>
        <div
          v-if="menuItem.items && !ignoreChildren"
          class="grow cursor-pointer"
          @click="toggle(menuItem)"
        >
          {{ menuItem.name }}
        </div>
        <NuxtLink
          v-else
          :to="menuItem.url || '#'"
          class="grow"
          @click="handleNavigate(menuItem)"
          >{{ menuItem.name }}</NuxtLink
        >
        <div class="flex-[0_0_25px]">
          <button
            v-if="menuItem.items && !ignoreChildren"
            type="button"
            :aria-label="menuItem.name"
            @click="toggle(menuItem)"
          >
            <i class="m-icon-arrow-breadcrumb-right-blk text-xl"></i>
          </button>
        </div>
      </li>
      <li v-if="menuItem.divider" :class="{ 'px-[15px]': level === 1 }">
        <div class="bg-black w-full h-px my-[10px]"></div>
      </li>
    </template>
    <li
      v-if="showAllProductsLink"
      class="leading-9 flex items-center font-medium text-sm hover:text-brand-primary"
    >
      <NuxtLink
        :to="allProductsUrl"
        class="grow"
        @click="$emit('leave-page', allProductsUrl)"
        >{{ t("all_products") }}</NuxtLink
      >
    </li>
  </ul>
</template>

<style scoped>
@keyframes slide-in-level-1 {
  0% {
    opacity: 0;
    left: -328px;
  }
  50% {
    opacity: 1;
    left: 20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes slide-in-level-2 {
  0% {
    opacity: 0;
    left: -328px;
  }
  50% {
    opacity: 1;
    left: 348px;
  }
  100% {
    opacity: 1;
    left: 328px;
  }
}

@keyframes slide-in-level-3 {
  0% {
    opacity: 0;
    left: -328px;
  }
  50% {
    opacity: 1;
    left: 676px;
  }
  100% {
    opacity: 1;
    left: 656px;
  }
}

@keyframes slide-out-level-2 {
  0% {
    opacity: 1;
    left: 328px;
  }
  100% {
    opacity: 0;
    left: -328px;
  }
}

@keyframes slide-out-level-3 {
  0% {
    opacity: 1;
    left: 656px;
  }
  100% {
    opacity: 0;
    left: -328px;
  }
}

.level-container {
  left: -328px;
}

.level-container > li {
  transition: padding-left 500ms ease-in-out;
}

.level-visible.level-1 {
  animation-name: slide-in-level-1;
}

.level-visible.level-2 {
  animation-name: slide-in-level-2;
}

.level-disappears.level-2,
.level-replaces.level-2 {
  animation-name: slide-out-level-2;
}

.level-visible.level-3 {
  animation-name: slide-in-level-3;
}

.level-disappears.level-3,
.level-replaces.level-3 {
  animation-name: slide-out-level-3;
}

.level-visible,
.level-disappears {
  animation-duration: 350ms;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.level-replaces.level-visible {
  animation-duration: 175ms;
}

.custom-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #888 #f1f1f1; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Width of the entire scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}
</style>
