<script setup lang="ts">
const { cmsPageSettings } = useMainStore();
const { getValueByKeyFromKeyValueData } = useHelpers();

const backgroundColor = computed(() => {
  if (!cmsPageSettings) {
    return null;
  }

  const value = getValueByKeyFromKeyValueData(
    cmsPageSettings.key_value,
    "hello_bar_background",
  );

  if (value) {
    return value;
  }

  return null;
});
</script>
<template>
  <div
    class="hello-bar w-full h-[44px] flex items-center justify-center text-white text-14 xl:text-16 z-header"
    :class="{ 'bg-brand-primary': !backgroundColor }"
    :style="{ 'background-color': backgroundColor }"
  >
    <CmsPageToken id="hello-bar" />
  </div>
</template>
