<script lang="ts" setup>
import { useCartStore } from "~/stores/cartStore";
import { useMainStore } from "~/stores/main";
import { usePriceFormatter } from "~/utils/priceFormatter";

const mainStore = useMainStore();
const cartStore = useCartStore();
const { cartData } = storeToRefs(cartStore);
const { t } = useI18n();

// -----------------------
// reactive properties
// -----------------------
const cartDataFetched = ref(false);

const { data: shippingMethodsRef } = await useApiFetch("shipping-methods");

const itemsPrice = computed(() => {
  return usePriceFormatter(cartData.value.info.items_total);
});

// -----------------------
// vue events
// -----------------------
if (import.meta.client) {
  onMounted(async () => {
    await nextTick();
    cartStore.fetchCartData();
    cartDataFetched.value = true;
  });
}
</script>

<template>
  <div v-if="cartDataFetched" class="bg-white">
    <div class="mb-3 cursor-pointer flex justify-end">
      <i
        class="m-icon-cancel text-10 ml-auto"
        @click="mainStore.cartVisible = false"
      ></i>
    </div>
    <div v-if="cartStore.cartData?.products.length">
      <div class="mb-2 p-3 border-b-2 border-secondary00"><BasketItems /></div>
      <!--
      <div class="mb-2 p-3 border-b-2 border-secondary00">
        <CheckoutCoupon />
      </div>
      -->
      <div class="mb-2 p-3">
        <div class="grid grid-cols-2 text-sm">
          <div class="font-bold text-base mb-1">Termékek összesen</div>
          <div class="text-right font-bold text-base mb-1">
            {{ itemsPrice }}
          </div>
          <div class="font-bold text-base mb-1">Szállítás</div>
          <div class="mb-1"></div>
          <div class="mb-1">
            {{
              shippingMethodsRef.find((item) => item.id === "personal")?.name
            }}
          </div>
          <div class="text-right mb-1">{{ t("checkout.shipping_free") }}</div>
          <div class="mb-1">
            {{ shippingMethodsRef.find((item) => item.id === "gls")?.name }}
          </div>
          <div class="text-right mb-1">
            {{
              usePriceFormatter(parseInt(cartData.info.all_shipping_fees.gls))
            }}
          </div>
          <div class="mb-1">
            {{
              shippingMethodsRef.find((item) => item.id === "gls_point")?.name
            }}
          </div>
          <div class="text-right mb-1">
            {{
              usePriceFormatter(
                parseInt(cartData.info.all_shipping_fees.gls_point),
              )
            }}
          </div>
          <div class="mb-1">
            {{ shippingMethodsRef.find((item) => item.id === "foxpost")?.name }}
          </div>
          <div class="text-right mb-1">
            {{
              usePriceFormatter(
                parseInt(cartData.info.all_shipping_fees.foxpost),
              )
            }}
          </div>
          <div class="mb-1">
            {{ shippingMethodsRef.find((item) => item.id === "mpl")?.name }}
          </div>
          <div class="text-right mb-1">
            {{
              usePriceFormatter(parseInt(cartData.info.all_shipping_fees.mpl))
            }}
          </div>
        </div>
      </div>

      <NuxtLink to="/rendeles" @click="mainStore.cartVisible = false">
        <BaseButton type="checkout" class="w-full py-2.5 md:py-2">{{
          t("checkout.proceed_to_checkout")
        }}</BaseButton>
      </NuxtLink>
    </div>
    <div v-if="cartStore.cartData?.products?.length === 0">
      {{ t("checkout.cart_empty") }}
    </div>
  </div>
</template>
