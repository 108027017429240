<script lang="ts" setup>
import { useReCaptcha } from "vue-recaptcha-v3";
import BaseModal from "~/components/Base/BaseModal.vue";

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const themeGroup = config.public.theme.group;
const recaptcha = useReCaptcha();
const { t } = useI18n();

// -----------------------
// props & emits
// -----------------------
withDefaults(
  defineProps<{
    show?: boolean;
  }>(),
  {
    show: true,
  },
);

const emit = defineEmits(["close"]);

// -----------------------
// reactive properties
// -----------------------
const nev = ref("");
const email = ref("");
const newsletterSignupLegal = ref();
const signupInProgress = ref(false);

// -----------------------
// computed properties
// -----------------------

// -----------------------
// helper functions
// -----------------------
const closeModal = () => {
  emit("close");
};

const { $api } = useNuxtApp();
const nlSignup = () => {
  recaptcha
    ?.recaptchaLoaded()
    .then((loaded) => {
      if (!loaded) {
        // TODO: handle not loaded state
        return;
      }

      recaptcha
        ?.executeRecaptcha("nlSignup")
        .then((token) => {
          signupInProgress.value = true;
          const postData = { name: nev.value, email: email.value, token };

          // console.log(postData);

          $api("newsletter-signup", {
            method: "POST",
            body: postData,
          })
            .then((/* result */) => {
              nev.value = "";
              email.value = "";
              newsletterSignupLegal.value = false;
              // console.log("result", result);
            })
            .catch(() => {
              // TODO: show an informative error message to user
            })
            .finally(() => {
              signupInProgress.value = false;
              emit("close");
            });
        })
        .catch(() => {
          // TODO: handling token fetching failure
        });
    })
    .catch(() => {
      // TODO: handle loading failure
    });
};
</script>

<template>
  <BaseModal
    :show="show"
    :autoclose="true"
    :nopadding="true"
    @close="closeModal"
  >
    <div class="flex flex-col items-center bg-brand-secondary">
      <div class="flex flex-col md:flex-row items-center">
        <div
          class="w-full md:w-[450px] flex flex-col items-center justify-center h-full py-4"
        >
          <img
            v-if="themeGroup === 'tnf'"
            :src="`/images/theme/${themeGroup}/logo_desktop_240829.svg`"
            loading="lazy"
            class="h-[60px]"
          />
          <img
            v-if="themeGroup === 'mountex'"
            :src="`/images/theme/${themeGroup}/logo_desktop_250116.svg`"
            loading="lazy"
            class="h-[60px]"
          />
          <h1 class="text-4xl font-lora text-center font-bold mt-[30px] mb-2">
            {{ t("newsletter_modal.title") }}
          </h1>
          <h2 class="text-xl mb-4">{{ t("newsletter_modal.subtitle") }}</h2>
          <div class="px-5 mb-2 w-full">
            <input
              v-model="nev"
              type="text"
              class="w-full p-2 py-3 text-black rounded border border-black"
              :placeholder="t('name')"
              name="nev"
            />
          </div>
          <div class="px-5 mb-2 w-full">
            <input
              v-model="email"
              type="email"
              class="w-full p-2 py-3 text-black rounded border border-black"
              :placeholder="t('email')"
              name="email"
            />
          </div>
          <div class="px-5 mb-2 w-full">
            <BaseButton
              :disabled="
                !newsletterSignupLegal || signupInProgress || !nev || !email
              "
              class="w-full text-black !rounded bg-brand-search py-[13px] disabled:bg-brand-primary"
              @click="nlSignup"
              >{{ t("i_sign_up") }}</BaseButton
            >
          </div>
          <div class="px-5 w-full">
            <BaseCheckbox2Way
              v-model="newsletterSignupLegal"
              :label="t('newsletter_modal.legal')"
              class="mb-3"
            />
          </div>
        </div>
        <div class="hidden md:block w-full md:w-[450px]">
          <img
            src="/images/newsletter-modal-250319.jpg"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </BaseModal>
</template>
