<script lang="ts" setup>
// import { useCartStore } from "~/stores/cartStore";
import { useMainStore } from "~/stores/main";

// const cartStore = useCartStore();
const mainStore = useMainStore();
const route = useRoute();
const basketPanelDiv = ref(null);

// -----------------------
// reactive properties
// -----------------------
// const cartDataFetched = ref(false);

// -----------------------
// methods
// -----------------------
const panelClasses = computed(() => {
  const classes: string[] = [
    "fixed",
    "overflow-auto",
    "basketPanel",
    "right-0",
    "z-modal",
    "shadow-xl",
  ];

  if (route.path === "/") {
    classes.push("basketPanelHeaderShow");
  } else {
    classes.push("basketPanelHeaderOnlyMenu");
  }

  if (mainStore.cartVisible) {
    classes.push("basketPanelActive");
  }

  return classes.join(" ");
});

// -----------------------
// vue events
// -----------------------
watch(
  () => mainStore.cartVisible,
  /* async */ () => {
    if (mainStore.cartVisible) {
      /*
      cartDataFetched.value = false;
      await nextTick();
      await cartStore.fetchCartData();
      cartDataFetched.value = true;
      // console.log("itt: " + cartStore.cartData);
      */
      setTimeout(function () {
        document.addEventListener("click", handleClickOutside);
      }, 100);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
  },
);

const handleClickOutside = (event) => {
  // Check if the clicked element is outside myDiv
  if (
    basketPanelDiv.value &&
    !basketPanelDiv.value.contains(event.target) &&
    mainStore.cartVisible
  ) {
    mainStore.cartVisible = false;
  }
};
</script>

<template>
  <div ref="basketPanelDiv" :class="panelClasses">
    <section class="w-100 bg-white overflow-auto p-3 basketPanelInner">
      <!--
      FIXME: a small flickering may be experienced when opening the cart due to 
             the conditional loading. On the other hand the API request is made
             only on demand
      -->
      <BasketBase v-if="mainStore.cartVisible" />
    </section>
  </div>
</template>
