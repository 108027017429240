<template>
  <!-- Hello bar -->
  <LayoutHelloBar v-if="showHelloBar" class="lg:hidden"></LayoutHelloBar>

  <!-- Menu -->
  <div
    id="mobileHeaderElement"
    class="logo lg:hidden w-full h-[56px] flex items-center justify-between fixed bg-brand-secondary z-header"
  >
    <!-- Logo -->
    <NuxtLink to="/">
      <img
        v-if="themeGroup === 'tnf'"
        :src="`/images/theme/${themeGroup}/logo_mobil_240829.svg`"
        class="h-[35px]"
        :alt="logoAlt"
        loading="lazy"
        width="76"
        height="35"
      />
      <img
        v-if="themeGroup === 'mountex'"
        :src="`/images/theme/${themeGroup}/logo_mobil_250116.svg`"
        class="h-[35px] ml-[5px]"
        :alt="logoAlt"
        loading="lazy"
        width="58"
        height="35"
      />
    </NuxtLink>

    <!-- Toolbox -->
    <div class="flex mr-[26px]">
      <a
        href="#"
        :aria-label="t('cart')"
        class="mr-[37px]"
        @click.prevent="toggleCart"
      >
        <i
          class="text-18 relative"
          :class="{
            'm-icon-cart': !isCartActive,
            'm-icon-cancel': isCartActive,
          }"
        >
          <span
            v-if="cartItemsNumber > 0 && !isCartActive"
            class="absolute text-center h-[20px] w-[20px] leading-[18px] bg-brand-highlight text-xs text-white rounded-full right-[-14px] top-[-9px]"
            >{{ cartItemsNumber }}</span
          >
        </i>
      </a>

      <ClientOnly>
        <a
          href="#"
          :aria-label="t('search')"
          class="mr-[37px]"
          :class="{ relative: isIOS() }"
          @click.prevent="toggleSearch"
        >
          <input
            v-if="isIOS()"
            ref="fakeInput"
            type="text"
            class="absolute w-[18px] h-18px opacity-0"
          />
          <i
            class="text-18"
            :class="{
              'm-icon-search-black': !isSearchActive,
              'm-icon-cancel': isSearchActive,
            }"
          ></i>
        </a>
      </ClientOnly>

      <a href="#" :aria-label="t('menu')" @click.prevent="toggleMenu">
        <i
          class="text-18"
          :class="{
            'm-icon-menu': !isMenuActive,
            'm-icon-cancel': isMenuActive,
          }"
        ></i>
      </a>
    </div>
  </div>

  <!-- First level menu items -->
  <div
    class="lg:hidden first-level-menu-items fixed w-full z-header overflow-y-auto"
    :class="{ expanded: isMenuActive }"
  >
    <template v-for="menuItem in mainStore.menuItems" :key="menuItem.name">
      <div class="menu-item flex items-center">
        <div class="menu-item-icon flex justify-center">
          <template v-if="menuItem.url">
            <NuxtLink
              :to="menuItem.url"
              :title="menuItem.name"
              @click="handleMenuItemClick(menuItem.url)"
            >
              <NuxtImg
                v-if="isIconImage(menuItem.icon)"
                :src="getAssetUrl(menuItem.icon)"
                :alt="menuItem.name"
                format="webp"
                loading="lazy"
                width="25"
              />
              <i
                v-else-if="isIconFont(menuItem.icon)"
                :class="menuItem.icon"
                class="text-2xl"
              ></i>
            </NuxtLink>
          </template>
          <template v-else>
            <button
              type="button"
              :aria-label="menuItem.name"
              @click="showSecondLevelMenu(menuItem)"
            >
              <NuxtImg
                v-if="isIconImage(menuItem.icon)"
                :src="getAssetUrl(menuItem.icon)"
                :alt="menuItem.name"
                format="webp"
                loading="lazy"
                width="25"
              />
              <i
                v-else-if="isIconFont(menuItem.icon)"
                :class="menuItem.icon"
                class="text-2xl"
              ></i>
            </button>
          </template>
        </div>
        <div class="menu-item-text flex justify-between">
          <template v-if="menuItem.url">
            <NuxtLink
              :to="menuItem.url"
              :title="menuItem.name"
              @click="handleMenuItemClick(menuItem.url)"
              >{{ menuItem.name }}</NuxtLink
            >
          </template>
          <template v-else>
            <button
              type="button"
              :aria-label="menuItem.name"
              @click="showSecondLevelMenu(menuItem)"
            >
              {{ menuItem.name }}
            </button>
          </template>

          <template v-if="menuItem.items">
            <button
              type="button"
              :aria-label="menuItem.name"
              @click="showSecondLevelMenu(menuItem)"
            >
              <i class="m-icon-arrow-breadcrumb-right-blk text-xl"></i>
            </button>
          </template>
        </div>
      </div>
      <div
        v-if="menuItem.divider"
        class="divider bg-black w-full h-px my-[10px]"
      ></div>
    </template>
  </div>

  <!-- Second level menu items -->
  <div
    class="lg:hidden second-level-menu-items fixed w-full z-header overflow-y-auto"
    :class="{ expanded: isMenuActive && currentMenuLevel === 2 }"
  >
    <div class="navigation flex items-center justify-between">
      <button :aria-label="t('back')" @click="hideSecondLevelMenu">
        <i class="m-icon-arrow-left text-2xl"></i>
      </button>
      <button :aria-label="t('back')" @click="active = null">
        <i class="m-icon-cancel text-2xl"></i>
      </button>
    </div>
    <div v-if="secondLevelAllProductsUrl" class="menu-item">
      <div class="menu-item-text h-full flex justify-between items-center">
        <NuxtLink
          :to="secondLevelAllProductsUrl"
          @click="handleMenuItemClick(secondLevelAllProductsUrl)"
        >
          {{ t("all_products") }}
        </NuxtLink>
      </div>
    </div>
    <template v-for="menuItem in secondLevelMenuItems" :key="menuItem.name">
      <div class="menu-item">
        <div class="menu-item-text h-full flex justify-between items-center">
          <template v-if="menuItem.url">
            <NuxtLink
              :to="menuItem.url"
              :title="menuItem.name"
              @click="handleMenuItemClick(menuItem.url)"
              >{{ menuItem.name }}</NuxtLink
            >
          </template>
          <template v-else>
            <button
              :aria-label="menuItem.name"
              type="button"
              @click="showThirdLevelMenu(menuItem)"
            >
              {{ menuItem.name }}
            </button>
          </template>

          <template v-if="menuItem.items">
            <button
              :aria-label="menuItem.name"
              type="button"
              @click="showThirdLevelMenu(menuItem)"
            >
              <i class="m-icon-arrow-breadcrumb-right-blk text-xl"></i>
            </button>
          </template>
        </div>
      </div>
      <div
        v-if="menuItem.divider"
        class="divider bg-black w-full h-px my-[10px]"
      ></div>
    </template>
  </div>

  <!-- Third level menu items -->
  <div
    class="lg:hidden third-level-menu-items fixed w-full z-header overflow-y-auto"
    :class="{ expanded: isMenuActive && currentMenuLevel === 3 }"
  >
    <div class="navigation flex items-center justify-between">
      <button :aria-label="t('back')" @click="hideThirdLevelMenu">
        <i class="m-icon-arrow-left text-2xl"></i>
      </button>
      <button :aria-label="t('back')" @click="active = null">
        <i class="m-icon-cancel text-2xl"></i>
      </button>
    </div>
    <div v-if="thirdLevelAllProductsUrl" class="menu-item flex items-center">
      <div class="menu-item-text">
        <NuxtLink
          :to="thirdLevelAllProductsUrl"
          @click="handleMenuItemClick(thirdLevelAllProductsUrl)"
        >
          {{ t("all_products") }}
        </NuxtLink>
      </div>
    </div>
    <template v-for="menuItem in thirdLevelMenuItems" :key="menuItem.name">
      <div class="menu-item flex items-center">
        <div class="menu-item-text">
          <template v-if="menuItem.url">
            <NuxtLink
              :to="menuItem.url"
              :title="menuItem.name"
              @click="handleMenuItemClick(menuItem.url)"
              >{{ menuItem.name }}</NuxtLink
            >
          </template>
          <template v-else>
            <button :aria-label="menuItem.name" type="button">
              {{ menuItem.name }}
            </button>
          </template>
        </div>
      </div>
      <div
        v-if="menuItem.divider"
        class="divider bg-black w-full h-px my-[10px]"
      ></div>
    </template>
  </div>

  <!-- Cart panel -->
  <BasketBase
    v-if="isCartActive"
    class="lg:hidden cart-panel fixed w-full z-header overflow-y-auto bottom-0 bg-white text-16 p-[20px]"
    :class="{
      'top-[100px]': route.path === '/',
      'top-[56px]': route.path !== '/',
    }"
  />

  <!-- Searchbox -->
  <div
    v-if="isSearchActive"
    class="lg:hidden search w-full h-full fixed bg-brand-surface z-[1249]"
  >
    <div
      ref="searchBar"
      class="search-bar relative"
      :class="{ expanded: isSearchExpanded }"
    >
      <SearchBox :autofocus="true" />
    </div>
  </div>
</template>

<script setup>
import { useMainStore } from "~/stores/main";
import { useCartStore } from "~/stores/cartStore";
import { viewCart as sendViewCartEvent } from "~/composables/useGoogleAnalytics";

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const mainStore = useMainStore();
const cartStore = useCartStore();
const route = useRoute();
const { isIconFont, isIconImage, getAssetUrl, isIOS } = useHelpers();
const { t } = useI18n();

// -----------------------
// reactive properties
// -----------------------
const active = ref(null);
const currentMenuLevel = ref(1);
const secondLevelMenuItems = ref([]);
const secondLevelAllProductsUrl = ref(null);
const thirdLevelMenuItems = ref([]);
const thirdLevelAllProductsUrl = ref(null);
const searchBar = ref(null);
const isSearchExpanded = ref(false);
const fakeInput = ref();
const themeGroup = config.public.theme.group;

// -----------------------
// computed properties
// -----------------------
const showHelloBar = computed(() => {
  return route.path === "/" && themeGroup === "mountex";
});

/*
const cartItemsNumber = computed(() => {
  if (typeof cartStore.cartData === "undefined") {
    cartStore.fetchCartData();
  }
  if (cartStore.cartData?.products?.length)
    return cartStore.cartData?.products?.length;
  return 0;
});
*/

const logoAlt = computed(() => {
  if (config.public.theme.group === "tnf") return "The North Face webáruház";
  return "Mountex";
});

const isCartActive = computed(() => {
  return active.value === "cart";
});

const isSearchActive = computed(() => {
  return active.value === "search";
});

const isMenuActive = computed(() => {
  return active.value === "menu";
});

const cartItemsNumber = computed(() => {
  if (!import.meta.client) return 0;
  if (typeof cartStore.cartData === "undefined") {
    cartStore.fetchCartData();
  }
  if (cartStore.cartData?.products?.length)
    return cartStore.cartData?.products?.length;
  return 0;
});

// -----------------------
// helper functions
// -----------------------
const showSecondLevelMenu = (menuItem) => {
  if (!menuItem.items) {
    return;
  }

  if (menuItem.url && !("hide_all_products_link" in menuItem)) {
    secondLevelAllProductsUrl.value = menuItem.url;
  }

  secondLevelMenuItems.value = menuItem.items;
  currentMenuLevel.value = 2;
};

const hideSecondLevelMenu = () => {
  secondLevelAllProductsUrl.value = null;
  secondLevelMenuItems.value = [];
  currentMenuLevel.value = 1;
};

const showThirdLevelMenu = (menuItem) => {
  if (!menuItem.items) {
    return;
  }

  if (menuItem.url && !("hide_all_products_link" in menuItem)) {
    thirdLevelAllProductsUrl.value = menuItem.url;
  }

  thirdLevelMenuItems.value = menuItem.items;
  currentMenuLevel.value = 3;
};

const hideThirdLevelMenu = () => {
  thirdLevelAllProductsUrl.value = null;
  thirdLevelMenuItems.value = [];
  currentMenuLevel.value = 2;
};

const toggleCart = () => {
  active.value = active.value === "cart" ? null : "cart";

  if (active.value === "cart") {
    sendViewCartEvent();
  }
};

const toggleSearch = () => {
  isSearchExpanded.value = false;

  setTimeout(() => {
    active.value = active.value === "search" ? null : "search";
  }, 400);
};

const toggleMenu = () => {
  active.value = active.value === "menu" ? null : "menu";
};

const handleMenuItemClick = (targetUrl) => {
  if (route.path === targetUrl) {
    active.value = null;
  }
};

// -----------------------
// vue events
// -----------------------
watch(
  () => route.path,
  () => {
    active.value = null;
  },
);

watch(active, (value) => {
  if (value !== "menu") {
    currentMenuLevel.value = 1;
    secondLevelMenuItems.value = [];
    thirdLevelMenuItems.value = [];
    secondLevelAllProductsUrl.value = null;
    thirdLevelAllProductsUrl.value = null;
  }

  if (value === "cart") {
    mainStore.cartVisible = true;
  } else {
    mainStore.cartVisible = false;
  }

  if (value === "search") {
    const expandedFn = setInterval(() => {
      if (searchBar.value instanceof Element) {
        isSearchExpanded.value = true;
        clearTimeout(expandedFn);
      }
    }, 50);
  } else {
    isSearchExpanded.value = false;

    if (fakeInput.value instanceof HTMLInputElement) {
      fakeInput.value.blur();
    }
  }
});

watch(
  () => mainStore.cartVisible,
  (value) => {
    if (value && active.value !== "cart") {
      active.value = "cart";
    } else if (!value && active.value === "cart") {
      active.value = null;
    }
  },
);

watch(
  () => mainStore.isMobile,
  () => {
    active.value = null;
  },
);
</script>

<style lang="scss" scoped>
$hero-height: 44px;
$menu-height: 56px;
$search-bar-height: 78px;

.hello-bar {
  position: fixed;
  height: $hero-height;
}

.logo {
  top: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.first-level-menu-items,
.second-level-menu-items,
.third-level-menu-items {
  top: calc($menu-height);
  transform: translateX(100%);
  height: calc(100% - calc($menu-height));
  padding: 0 20px 20px 20px;
  transition: all ease-in-out 400ms;
  background-color: white;
  font-size: 16px;
}

.first-level-menu-items.expanded,
.second-level-menu-items.expanded,
.third-level-menu-items.expanded {
  transform: translateX(0);
}

.menu-item {
  height: 38px;
}

.second-level-menu-items .menu-item,
.third-level-menu-items .menu-item {
  padding: 0 38px 0 32px !important;
}

.first-level-menu-items .menu-item {
  transition: margin-left ease-in 400ms;
}

.first-level-menu-items .menu-item:hover {
  margin-left: 10px;
}

.menu-item-icon {
  flex: 0 0 82px;
}

.menu-item-icon img,
.menu-item-icon i {
  width: 25px;
}

.first-level-menu-items .menu-item-text {
  flex: 1 0 auto;
}

.menu-item-text {
  transition: color ease-in 500ms;
}

.menu-item:hover .menu-item-text {
  color: #b27c56;
}

.second-level-menu-items .navigation,
.second-level-menu-items .menu-item,
.third-level-menu-items .navigation,
.third-level-menu-items .menu-item {
  padding: 0 32px;
  height: 38px;
  margin-bottom: 10px;
}

.second-level-menu-items .divider,
.third-level-menu-items .divider {
  margin-left: 32px;
  width: calc(100% - 32px - 38px);
}

.navigation {
  margin-top: -50px;
  transition: margin-top ease-in-out 750ms;
}

.second-level-menu-items.expanded .navigation,
.third-level-menu-items.expanded .navigation {
  margin-top: 0;
}

.navigation img {
  width: 25px;
  height: 25px;
}

.search-bar {
  transform: translateY(calc(($hero-height + $search-bar-height) * -1));
  top: $menu-height;
  height: $search-bar-height;
  padding: 19px 16px 0;
  transition: transform ease-in-out 400ms;
}

.home-page .search-bar {
  top: calc($hero-height + $menu-height);
}

.home-page.theme-group-tnf .search-bar {
  top: $menu-height;
}

.search-bar.expanded {
  transform: translateY(0);
}

.search input {
  width: 100%;
  border-radius: 20px;
  padding: 8px 50px 8px 19px;
}

.search button {
  position: absolute;
  right: 31px;
  top: 23px;
  width: 35px;
  height: 30px;
}

.home-page .logo {
  top: $hero-height;
}

.theme-group-tnf.home-page .logo {
  top: 0 !important;
}

.home-page .first-level-menu-items,
.home-page .second-level-menu-items,
.home-page .third-level-menu-items {
  top: calc($hero-height + $menu-height);
  height: calc(100% - calc($hero-height + $menu-height));
}

.theme-group-tnf.home-page .first-level-menu-items,
.theme-group-tnf.home-page .second-level-menu-items,
.theme-group-tnf.home-page .third-level-menu-items {
  top: calc($menu-height);
  height: calc(100% - calc($menu-height));
}

.home-page .account-panel,
.home-page .cart-panel {
  max-height: calc(100% - calc($hero-height + $menu-height));
}

.theme-group-tnf.home-page .account-panel,
.theme-group-tnf.home-page .cart-panel {
  max-height: calc(100% - calc($menu-height));
}
</style>
