<template>
  <header
    id="headerElement"
    class="bg-white w-full z-header hidden lg:block"
    :class="{
      [`visibility-${mainStore.headerVisibilityState}`]: true,
      [mainStore.headerPosition]: true,
    }"
    :style="{
      top: isHeaderPositionAbsolute ? mainStore.headerTop + 'px' : null,
    }"
  >
    <!-- Hello bar -->
    <LayoutHelloBar v-if="showHelloBar"></LayoutHelloBar>

    <!-- Menu & submenus -->
    <LayoutDesktopMenu
      :show="isMenuVisible"
      :delayed-hide="delayedMenuHide"
      @hide="isMenuVisible = false"
    ></LayoutDesktopMenu>

    <div
      class="menu-bar grid grid-cols-3 grid-rows-menu-bar justify-items-center items-center bg-brand-secondary"
    >
      <div class="menu-search-wrapper flex justify-self-start">
        <template v-if="route.path !== '/rendeles'">
          <!-- Blur layer -->
          <div
            v-if="isMenuVisible"
            class="blur-layer z-blur-background backdrop-blur-xs w-full"
            :class="{
              [mainStore.headerPosition]: true,
            }"
            :style="{
              top: isHeaderPositionAbsolute
                ? temporaryBlurLayerTop + 'px'
                : null,
              height: isHeaderPositionAbsolute
                ? `${temporaryBlurLayerHeight}px`
                : null,
            }"
          ></div>

          <nav class="main-menu flex items-center relative">
            <!-- Hamburger button -->
            <button
              class="hamburger-button flex flex-col items-center text-15 w-[83px]"
              :aria-label="t('menu')"
              @click="isMenuVisible = !isMenuVisible"
              @mouseenter="delayedMenuHide = false"
              @mouseleave="delayedMenuHide = true"
            >
              <i class="m-icon-menu text-[25px]"></i>
            </button>
          </nav>
          <div class="search-bar relative ml-[42px]">
            <SearchBox />
          </div>
        </template>
        <template v-else>
          <a
            class="pl-5 text-brand-link font-medium"
            href="javascript:history.go(-1)"
            >&lt; <span class="underline">{{ t("continue_browsing") }}</span></a
          >
        </template>
      </div>
      <div class="logo">
        <NuxtLink to="/">
          <img
            v-if="themeGroup === 'tnf'"
            :src="`/images/theme/${themeGroup}/logo_desktop_240829.svg`"
            :alt="logoAlt"
            loading="lazy"
            class="w-[97px] h-[45px]"
          />
          <img
            v-if="themeGroup === 'mountex'"
            :src="`/images/theme/${themeGroup}/logo_desktop_250116.svg`"
            :alt="logoAlt"
            loading="lazy"
            class="w-[153px] h-[45px]"
          />
        </NuxtLink>
      </div>

      <div class="toolbox flex mr-[21px] justify-self-end">
        <a
          v-if="showStores"
          href="/boltok"
          class="mr-[50px]"
          :aria-label="t('our_stores')"
        >
          <i class="m-icon-stores text-xl"></i>
        </a>
        <button
          v-if="route.path !== '/rendeles'"
          :aria-label="t('cart')"
          type="button"
          class="cursor-pointer mr-[50px]"
          @click="toggleCart"
        >
          <i class="m-icon-cart text-xl relative">
            <span
              v-if="cartItemsNumber > 0"
              class="absolute h-[24px] w-[24px] bg-brand-highlight text-xs text-white rounded-full right-[-14px] top-[-9px]"
              ><span class="relative top-[3px] font-sans">{{
                cartItemsNumber
              }}</span></span
            >
          </i>
        </button>
        <!--
        <a
          v-if="showFavourite"
          href="#"
          class="mr-[50px]"
          :aria-label="t('favourites')"
        >
          <i class="m-icon-favourite text-2xl"></i>
        </a>
        -->
        <!--
        <button type="button" @click="$emit('show-profile-modal')">
          <i class="m-icon-user text-2xl"></i>
        </button>
        -->
        <a
          :href="`/${config.public.routes.profile}`"
          :aria-label="t('profile.profile')"
        >
          <i class="m-icon-user text-xl"></i>
        </a>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useMainStore } from "~/stores/main";
import { useCartStore } from "~/stores/cartStore";
import { viewCart as sendViewCartEvent } from "~/composables/useGoogleAnalytics";

// -----------------------
// props & emits
// -----------------------
// defineEmits(["show-profile-modal"]);

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const mainStore = useMainStore();
const route = useRoute();
const cartStore = useCartStore();
const { t } = useI18n();

// -----------------------
// reactive properties
// -----------------------
const isMenuVisible = ref(false);
const delayedMenuHide = ref(null);
const themeGroup = config.public.theme.group;

// -----------------------
// computed properties
// -----------------------
/*
const showFavourite = computed(() => {
  if (route.path === "/rendeles") return false;
  if (config.public.theme.group === "tnf") return false;
  return true;
});
*/

const isHeaderPositionAbsolute = computed(() => {
  return mainStore.headerPosition === "absolute";
});

const showStores = computed(() => {
  if (route.path === "/rendeles") return false;
  if (config.public.theme.group === "tnf") return false;
  return true;
});

const logoAlt = computed(() => {
  if (config.public.theme.group === "tnf") return "The North Face webáruház";
  return "Mountex";
});

const showHelloBar = computed(() => {
  return route.path === "/";
});

const cartItemsNumber = computed(() => {
  if (!import.meta.client) return 0;
  if (typeof cartStore.cartData === "undefined") {
    cartStore.fetchCartData();
  }
  if (cartStore.cartData?.products?.length)
    return cartStore.cartData?.products?.length;
  return 0;
});

const temporaryBlurLayerTop = computed(() => {
  return (
    56 /* $menu-height */ + (route.path === "/" ? 44 /* $hero-height */ : 0)
  );
});

const temporaryBlurLayerHeight = computed(() => {
  return document.body.clientHeight - temporaryBlurLayerTop.value;
});

// -----------------------
// helper functions
// -----------------------
const toggleCart = () => {
  mainStore.cartVisible = !mainStore.cartVisible;

  if (mainStore.cartVisible) {
    sendViewCartEvent();
  }
};

// -----------------------
// hooks
// -----------------------
watch(isMenuVisible, (visible) => {
  if (!visible) {
    mainStore.headerPosition = "fixed";
  }
});

onMounted(() => {
  let lastScrollTop = 0;

  if (route.path !== "/") {
    mainStore.headerVisibilityState = "only-menu";
  }

  window.addEventListener("scroll", () => {
    const scrollTop =
      window.scrollY ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    const scrollDown = lastScrollTop < scrollTop;

    if (scrollDown && isMenuVisible.value) {
      if (isHeaderPositionAbsolute.value === false) {
        // Set header position only once
        mainStore.headerPosition = "absolute";
        mainStore.headerTop = scrollTop;
      }

      return;
    } else {
      mainStore.headerPosition = "fixed";
    }

    lastScrollTop = scrollTop;
  });
});
</script>
